import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MobileDetect from 'mobile-detect';

import OneButtonImg from './resources/img/one-button.svg';
import AppStoreBadgeImg from './resources/img/app-store-badge.svg';
import GooglePlayBadgeImg from './resources/img/google-play-badge.svg';
import Background from './resources/img/background.svg';

import { ReactComponent as Logo } from './resources/img/logo.svg';
import { ReactComponent as Avatar1 } from './resources/img/avatar-1.svg';
import { ReactComponent as Avatar2 } from './resources/img/avatar-2.svg';
import { ReactComponent as Avatar3 } from './resources/img/avatar-3.svg';

const md = new MobileDetect(window.navigator.userAgent);

function Default() {
  let googlePlayWasClicked = false;
  let appStoreWasClicked = false;

  const { t } = useTranslation();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.startsWith('/add/')) {
      setTimeout(() => {
        if (md.os() === 'iOS') {
          window.location.replace('https://apps.apple.com/app/id1496340692');
        } else if (md.os() === 'AndroidOS') {
          window.location.replace('https://play.google.com/store/apps/details?id=app.goinhome');
        }
      }, 300);
    }
  }, [location]);

  const handleGooglePlayClick = () => {
    if (!googlePlayWasClicked) {
      window.gtag('event', 'GooglePlayClick');
    }
    googlePlayWasClicked = true;
  }

  const handleAppStoreClick = () => {
    if (!appStoreWasClicked) {
      window.gtag('event', 'AppStoreClick');
    }
    appStoreWasClicked = true;
  }

  return (
    <>
      <section className="info info-mobile">
        <div className="container">
          <div className="logo-container">
            <Logo className="margin-bottom" />
          </div>
          <h4 className="margin-bottom centered">
            {t('default.info.header1')}
          </h4>
          <img src={Background} alt="" className="background" />
          <h4 className="margin-top centered">
            {t('default.info.header2')}
          </h4>
        </div>
      </section>
      <section className="info info-desktop">
        <div className="container">
          <div className="info-left">
            <div className="logo-container">
              <Logo className="margin-bottom" />
            </div>
            <h3 className="margin-bottom">
              {t('default.info.header1')}
            </h3>
            <h3 className="margin-top">
              {t('default.info.header2')}
            </h3>
          </div>
          <div className="info-right">
            <img src={Background} alt="" className="background" />
          </div>
        </div>
      </section>
      <section className="main">
        <div className="container">
          <div>
            <h1 className="centered">{t('default.appName')}</h1>
            <h5 className="centered">
              {t('default.appDescription')}
            </h5>
          </div>
          <img src={OneButtonImg} className="glow margin-top margin-bottom" alt="Button" />
          <h5 className="centered margin-top">{t('default.main.header')}</h5>
          <div className="store-links margin-top">
            <a href="https://apps.apple.com/app/id1496340692" onClick={handleAppStoreClick}>
              <img src={AppStoreBadgeImg} alt="Download on the App Store" className="app-store-img" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=app.goinhome" onClick={handleGooglePlayClick}>
              <img src={GooglePlayBadgeImg} alt="Get it on Google Play" className="google-play-img" />
            </a>
          </div>
          <div className="sharethis-inline-share-buttons margin-top" />
          <Avatar1 className="avatar avatar-1" />
          <Avatar2 className="avatar avatar-2" />
          <Avatar3 className="avatar avatar-3" />
        </div>
      </section>
    </>
  );
}

export default Default;
