import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Default from './Default';
import Support from './Support';
import Theme from './Theme';
import './App.css';

function App() {
  return (
    <Theme>
      <Router>
        <Switch>
          <Route exact path="/support" component={Support} />
          <Route component={Default} />
        </Switch>
      </Router>
    </Theme>
  );
}

export default App;
