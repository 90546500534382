import React from 'react';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#152c50',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontSize: '1.6rem',
        letterSpacing: 0,
        lineHeight: 1.4,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f5f6f7',
      },
    },
  },
});

export default function Theme({ children }) {
  return (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  );
}