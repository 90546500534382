import React from 'react';
import { Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from './resources/img/logo.svg';
import SergAvatar from './resources/img/serg-avatar.jpg';
import JuraAvatar from './resources/img/jura-avatar.jpg';
import { ReactComponent as Avatar1 } from './resources/img/avatar-1.svg';
import { ReactComponent as Avatar2 } from './resources/img/avatar-2.svg';
import { ReactComponent as Avatar3 } from './resources/img/avatar-3.svg';

function App() {
  const { t } = useTranslation();
  const [contact, setContact] = React.useState('');
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);
  const handleChange = event => {
    setContact(event.target.value);
  };
  const handleSubmit = async event => {
    event.preventDefault();
    try {
      setIsCompleted(false);
      setIsFailed(false);
      await axios.post(
        `${window.location.origin}/v1/users/settings`,
        { contact },
        {
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
        },
      );
      setIsCompleted(true);
      setTimeout(() => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.completeMessageHandler) {
          window.webkit.messageHandlers.completeMessageHandler.postMessage({ completed: true });
        }
      }, 1500);
    } catch (error) {
      setIsFailed(true);
    }
  };
  React.useEffect(() => {
    document.body.className = "overflow-hidden";
    return () => {
      document.body.className = "";
    }
  })
  return (
    <>
      <section className="support">
        <div className="support-top-container">
          <div className="margin-bottom">
            <Logo className="logo" />
            <AvatarGroup>
              <Avatar alt="Сергей Мошков" src={SergAvatar} className="owner-avatar" />
              <Avatar alt="Юрий Мошков" src={JuraAvatar} className="owner-avatar" />
            </AvatarGroup>
          </div>
          <h5>
            {t('support.header')} <strong>{t('default.appName')}</strong>
          </h5>
        </div>
        <div className="support-bottom-container">
          <p>
            {t('support.p1')}
          </p>
          <p>
            {t('support.p2')}
            <a href="https://www.instagram.com/goinhomeapp/" target="_blank">@goinhomeapp</a>
            {t('support.p3')}
          </p>
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder={t('support.contact')} name="value" value={contact} onChange={handleChange} />
            <input className="button-primary" type="submit" value={t('support.send')} style={{ width: '100%' }} />
            {isCompleted && (
              <p style={{ color: '#019944' }}>
                {t('support.completed')}
              </p>
            )}
            {isFailed && (
              <p style={{ color: '#CF000F'}}>
                {t('support.failed')}
              </p>
            )}
          </form>
          <Avatar1 className="avatar avatar-1" />
          <Avatar2 className="avatar avatar-2" />
          <Avatar3 className="avatar avatar-3" />
        </div>
      </section>
    </>
  );
}

export default App;
